<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        Retenção da carteira - Vendedor
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-row class="mb-3">
          <v-col
            cols="12"
            md="4"
            class="pb-1"
          >
            <v-autocomplete
              v-model="vendorName"
              :items="listVendors[0]"
              item-text="name"
              item-value="name"
              label="Vendedor"
              outlined
              dense
              hide-details
              cols="12"
              md="3"
              sm="4"
              lg="3"
              class="pb-1"
              @input="onInput"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        group-by="nomevendedor"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
        hide-default-footer
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.reference === loadingCircle ? false : true"
            medium
            class="me-2"
            @click="viewSales(item)"
          >
            {{ icons.mdiExpandAllOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.reference === loadingCircle ? true : false"
            indeterminate
            :size="25"
            color="gray"
          ></v-progress-circular>
        </template>
        <template
          v-slot:no-data
        >
          <span :class="classNoDataText">{{ noDataText }}</span>
        </template>
        <template
          v-slot:group.header="{items}"
        >
          <td
            colspan="11"
          >
            {{ items[0].nomevendedor }}
          </td>
        </template>
      </v-data-table>

      <!-- <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text> -->
    </v-card>
    <v-dialog
      v-model="openModal"
      width="1600"
    >
      <modal-retention-home
        :key="reference"
        :vendor-name="vendor_name"
        :reference="reference"
        @close="openModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCalculatorVariantOutline, mdiExpandAllOutline, mdiFilterOutline, mdiMinusCircleOutline,
} from '@mdi/js'
import ModalRetentionHome from './ModalRetentioHome.vue'

export default {
  components: {
    ModalRetentionHome,
  },
  data() {
    return {
      reference: '',
      vendor_name: '',
      openModal: false,
      itemsModal: [],
      disabledButtonFilter: true,
      classNoDataText: '',
      noDataText: '',
      vendorName: '',
      listVendors: [],
      icons: {
        mdiCalculatorVariantOutline,
        mdiFilterOutline,
        mdiMinusCircleOutline,
        mdiExpandAllOutline,
      },
      page: 1,
      headers: [
        { text: 'REFERENCIA', value: 'reference' },
        { text: 'VENDA', value: 'venda', align: 'center' },
        { text: 'PARC 01', value: 'parcela01', align: 'center' },
        { text: 'PARC 02', value: 'parcela02', align: 'center' },
        { text: 'PARC 03', value: 'parcela03', align: 'center' },
        { text: 'PARC 04', value: 'parcela04', align: 'center' },
        { text: 'PARC 05', value: 'parcela05', align: 'center' },
        { text: '% PARC 2', value: 'retencao02', align: 'center' },
        { text: '% PARC 5', value: 'retencao05', align: 'center' },
        { text: 'RETENÇÃO ( 1º a 5º )', value: 'desempenho', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },
      ],
      dataTable: [],
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      loading: false,
      loadingCircle: '',
    }
  },

  created() {
    this.getItemsListVendors()
    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
  },

  methods: {
    async onInput() {
      await this.getFilterItems()
    },

    async getItemsListVendors() {
      await axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.listVendors.push(res.data.data)
        })
    },

    // async nextPage() {
    //   this.loading = true
    //   this.dataTable = []
    //   const filter = {
    //     vendor_name: this.vendorName,
    //   }
    //   await axiosIns.post(`api/v1/integration/retention/portfolio_retention?page=${this.pageOptions.page}`, filter)
    //     .then(res => {
    //       this.dataTable = res.data.data
    //       this.pageOptions.itemsPerPage = res.data.per_page
    //       this.pageOptions.pageCount = res.data.last_page
    //       this.loading = false
    //     })
    // },

    async getFilterItems() {
      this.loading = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''

      const filter = {
        vendor_name: this.vendorName,
      }

      await axiosIns.post('/api/v1/integration/retention/portfolio_retention/', filter)
        .then(res => {
          this.dataTable = res.data.data
          this.loading = false

          // this.pageOptions.itemsPerPage = res.data.per_page
          // this.pageOptions.pageCount = res.data.last_page
        })
      this.loading = false
    },

    async viewSales(item) {
      this.reference = item.reference
      this.vendor_name = item.nomevendedor

      this.openModal = true
    },
    clearFilter() {
      this.dataTable = []
      this.vendorName = ''
      this.noDataText = 'SELECIONE UM FILTRO'
      this.classNoDataText = 'colorWarning'
    },

  },
}
</script>
<style>
.colorWarning {
  color: #ff9800 !important;
}
</style>
