import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex bd-highlight align-center flex-wrap"},[_vm._v(" Retenção da carteira - Vendedor ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap mt-5 pb-0"},[_c(VRow,{staticClass:"mb-3"},[_c(VCol,{staticClass:"pb-1",attrs:{"cols":"12","md":"4"}},[_c(VAutocomplete,{staticClass:"pb-1",attrs:{"items":_vm.listVendors[0],"item-text":"name","item-value":"name","label":"Vendedor","outlined":"","dense":"","hide-details":"","cols":"12","md":"3","sm":"4","lg":"3"},on:{"input":_vm.onInput},model:{value:(_vm.vendorName),callback:function ($$v) {_vm.vendorName=$$v},expression:"vendorName"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataTable,"group-by":"nomevendedor","loading-text":"Carregando dados...","loading":_vm.loading,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.reference === _vm.loadingCircle ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.viewSales(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiExpandAllOutline)+" ")]):_vm._e(),(item.reference === _vm.loadingCircle ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',{class:_vm.classNoDataText},[_vm._v(_vm._s(_vm.noDataText))])]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(items[0].nomevendedor)+" ")])]}}],null,true)})],1),_c(VDialog,{attrs:{"width":"1600"},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('modal-retention-home',{key:_vm.reference,attrs:{"vendor-name":_vm.vendor_name,"reference":_vm.reference},on:{"close":function($event){_vm.openModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }